<template>
  <div class="brands" ref="zjdgx">
    <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-pinpai"></use>
      </svg>
    </breadcrumbs>
    <a-spin :spinning="isLoadingList">
      <div class="content-wrapper">
        <div class="left-filter">
          <h3 @click="setCategory">
            <i
              class="iconfont icon-jiantou-zuo-cu"
              v-if="filter.categoryId"
            />All Categories
          </h3>
          <div class="category-tree">
            <category-new
              :filter="filter"
              ref="categoryTree"
              @setCategory="setCategory"
            />
          </div>
        </div>
        <div class="right-content">
          <div class="top">
            <h2>
              {{ filter.categoryName
              }}<artmisads-select
                :width="200"
                class="country"
                :dropdownMatchSelectWidth="false"
                v-model:value="filter.countryHost"
                :getPopupContainer="() => $refs.zjdgx"
                @change="(value, option) => setFilter('country', option)"
              >
                <a-select-option
                  class="country-select-item"
                  v-for="item in Object.keys(CURRENCY_MAPPER)"
                  :key="item.toLowerCase()"
                  :value="CURRENCY_MAPPER[item].host"
                  :currency="CURRENCY_MAPPER[item].currency"
                >
                  <svg class="icon" aria-hidden="true">
                    <use
                      :xlink:href="'#icon-guoqi-' + item.toLowerCase()"
                    ></use></svg>{{ CURRENCY_MAPPER[item].host }}
                </a-select-option>
              </artmisads-select>
            </h2>
            <div class="top-filter">
              <div class="flex-left">
                <top-pagination
                  :pagination="{
                    total: brandsCount,
                    size: filter.size,
                    current: filter.page,
                  }"
                  @pageChange="(page) => setFilter('page', page)"
                />
                <a-input-search
                  allow-clear
                  class="search"
                  placeholder="Search..."
                  v-model:value="filter.keywords"
                  @search="(v) => setFilter('keywords', v)"
                />
              </div>
              <div class="flex-right">
                <div class="deals-filter">
                  <span>Deals:</span>
                  <artemisads-segmented v-model:value="filter.deal" @change="(v) => setFilter('deal', v)"
                    :options="['All', 'Active', 'Upcoming', 'Off']" />
                </div>
                <artmisads-select
                  class="sort-select flex-right with-sort-icon"
                  v-model:value="filter.sort"
                  @change="(option) => setFilter('sort', option)"
                >
                  <a-select-option value="%2BbestSellers"
                    >Best Sellers</a-select-option
                  >
                  <a-select-option value="-newlyAdded"
                    >Newly Added</a-select-option
                  >
                  <a-select-option value="-commission"
                    >Commission</a-select-option
                  >
                  <a-select-option value="-price">Price</a-select-option>
                </artmisads-select>
              </div>
            </div>
          </div>
          <div :class="'list' + (brandsCount <= filter.size ? ' single-page' : '')">
            <a
              class="item"
              target="_blank"
              :href="'/publisher/brands/' + brand.id"
              v-for="brand in brands"
              :key="brand.id"
            >
              <div
                :class="'img-wrapper' + (brand.logoImageUrl ? '' : ' show-bg')"
              >
                <img
                  class="logo"
                  alt="Artemisads.com"
                  v-if="brand.logoImageUrl"
                  :src="brand.logoImageUrl"
                />
              </div>
              <div class="info">
                <p class="name">{{ brand.amazonBrandName }}</p>
                <p class="avg-commission">{{ brand.avgCommission }}% avg</p>
                <p class="biography">{{ brand.biography }}</p>
              </div>
            </a>
          </div>
          <a-pagination
            show-less-items
            class="pagination"
            :total="brandsCount"
            :pageSize="filter.size"
            :showSizeChanger="true"
            v-model:current="filter.page"
            v-if="brandsCount > filter.size"
            @change="(p) => setFilter('page', p)"
            :pageSizeOptions="['10', '20', '30', '50', '100']"
            @showSizeChange="(c, size) => setFilter('size', size)"
          />
          <no-data v-if="brandsCount == 0" />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { publisherApi } from '~/server'
import NoData from '~/components/NoData.vue'
import breadcrumbs from '../../seller/breadcrumbs.vue'
import CategoryNew from '~/components/CategoryNew.vue'
import { CURRENCY_MAPPER } from '../../../contants/common'
import TopPagination from '~/components/TopPagination.vue'

const brands = ref([])
const categories = ref([])
const brandsCount = ref(0)
const categoryTree = ref(null)
const isLoadingList = ref(true)
const countryCode = localStorage.getItem('selectedSite') || 'US'
const filter = ref({
  page: 1,
  size: 20,
  deal: 'Off',
  keywords: '',
  categoryId: null,
  countryCode,
  categoryLevel: null,
  categoryName: 'All',
  sort: '%2BbestSellers',
  countryHost: CURRENCY_MAPPER[countryCode].host,
})
const breadcrumbsData = {
  title: 'Brands Catalogue',
  hasTitleIcon: true,
  des: 'Find the brands you want to promote!',
  color: '#FFD358',
  links: [
    {
      name: 'Home',
      path: '/publisher/products',
    },
    {
      name: 'Brands',
      path: '/publisher/brands',
    },
  ],
}

publisherApi
  .getFirstLevelCategory({ countryCode: filter.value.countryCode })
  .then((res) => {
    if (res.success) {
      categories.value = res.data.map((item) => {
        return {
          level: 1,
          key: item.categoryId,
          title: item.fullName,
        }
      })
    }
  })

function setCategory(category) {
  if (category.categoryId) {
    const { categoryId, displayName, level } = category

    filter.value.categoryLevel = level
    filter.value.categoryId = categoryId
    filter.value.categoryName = displayName
  } else {
    filter.value.categoryId = null
    filter.value.categoryLevel = null
    filter.value.categoryName = 'All'
    categoryTree.value.getCategories({ categoryId: 0, level: 1 })
  }

  filter.value.page = 1
  getBrandsList()
}

function getBrandsList() {
  const params = { ...filter.value }

  Object.keys(params).forEach((key) => {
    if (
      ['categoryName', 'countryHost'].indexOf(key) != -1 ||
      ['', null, undefined].indexOf(params[key]) != -1
    ) {
      delete params[key]
    }
  })

  isLoadingList.value = true

  publisherApi.searchBrandsList(params).then((res) => {
    brands.value = res.data
    isLoadingList.value = false

    if (filter.value.page == 1) {
      brandsCount.value = res.total
    }
  })
}

getBrandsList()

function setFilter(type, option) {
  if (type == 'page') {
    filter.value.page = option
  } else {
    if (type == 'sort') {
      filter.value.sort = option
      filter.value.page = 1
    } else if (type == 'country') {
      filter.value.categoryId = null
      filter.value.categoryName = 'All'
      filter.value.countryHost = option.value
      filter.value.countryCode = option.key.toUpperCase()
      localStorage.setItem('selectedSite', filter.value.countryCode)
      categoryTree.value.getCategories({
        level: 1,
        categoryId: 0,
        countryChanged: true,
      })
    } else if (type == 'size') {
      filter.value.size = option
    } else if (type == 'keywords') {
      if (option.type) {
        if (option.type == 'click') {
          // clear event
          filter.value.page = 1
        } else {
          return
        }
      } else {
        filter.value.keywords = option
      }
    }

    filter.value.page = 1
  }

  getBrandsList()
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/ellipsis.less';
@import '../../../assets/less/country-select.less';

.brands {
  .left-filter > h3 {
    display: flex;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    margin-bottom: 10px;
    > .iconfont {
      width: auto;
      height: auto;
      color: #b7b4be;
      &.icon-henggang-jiacu {
        flex: 1;
        text-align: right;
      }
    }
    .icon-jiantou-zuo-cu {
      width: 16px;
      height: 16px;
      font-size: 14px;
      line-height: 16px;
      margin-right: 4px;
      color: var(--dark-2);
    }
  }
  .category-tree {
    margin-left: 10px;
  }
}

.content-wrapper {
  display: flex;
}
.left-filter {
  flex: 0 0 200px;
  margin-right: 20px;
  margin-bottom: 16px;
  :deep(.category-list) {
    overflow: hidden;
    max-height: unset;
    margin-bottom: 10px;
  }
}
.right-content {
  flex: 1;
  h2 {
    display: flex;
    font-size: 20px;
    line-height: 25px;
    align-items: center;
    padding-bottom: 12px;
    justify-content: space-between;
    border-bottom: 1px solid #e8e5ed;
    .ant-select {
      width: 180px;
      margin-top: -4px;
      :deep(.ant-select-selection-item) {
        display: flex;
        align-items: center;
        > .icon {
          font-size: 18px;
          margin-top: 2px;
          margin-right: 6px;
        }
      }
    }
  }
  :deep(.ant) {
    &-pagination {
      text-align: right;
      margin-bottom: 16px;
    }
  }
}
.top-filter {
  display: flex;
  margin: 20px 0;
  align-items: center;
  justify-content: space-between;
  .flex-left,
  .flex-right {
    gap: 16px;
    display: flex;
    align-items: center;
  }
  .deals-filter {
    > span {
      margin-right: 8px;
      color: var(--dark-2);
    }
  }
  .search {
    width: 200px;
  }
  .sort-select {
    width: 164px;
  }
}
.list {
  display: grid;
  grid-gap: 16px;
  margin-bottom: 16px;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (min-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }
  &.single-page {
    margin-bottom: 40px;
  }
  .item {
    display: flex;
    padding: 16px;
    position: relative;
    border-radius: 8px;
    align-items: center;
    border: 1px solid #e8e5ed;
  }
  .img-wrapper {
    height: 120px;
    display: flex;
    flex: 0 0 120px;
    min-width: 120px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    &.show-bg {
      background: var(--primary-image);
    }
    .logo {
      max-width: 120px;
      max-height: 120px;
    }
  }
  .info {
    padding-left: 16px;
  }
  .name {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }
  .avg-commission {
    font-size: 14px;
    margin: 4px 0 8px;
    line-height: 19px;
    color: #6c3df2;
  }
  .biography {
    opacity: 0.7;
    font-size: 13px;
    max-height: 54px;
    line-height: 18px;
    letter-spacing: 0.3px;
    .multiple-ellipsis(3);
  }
}
</style>